html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
*, *::before, *::after{
    box-sizing: inherit;
}

.Dropdown-option{
    margin-left: 10px;
    font-size: 12px;
}
.Dropdown-menu {
    min-height: 100%;
    max-height: 300px;
}
.Dropdown-control{
    border-radius: 9999px !important;
}


.grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
}

.ReactModal__Overlay {
    z-index: 10001;
}
.ReactModal__Content  {
    max-height: 100vh
}

/* .image-gallery-image {
    max-width: 400px !important;
    margin: auto !important;
    max-height: 400px !important;
} */

.slick-list {
    box-sizing: initial;
    padding: 25px 0px !important;
}
.scroll-margin {
    scroll-margin-block-start : 100px
}
.video {
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 2;
   
  }
.video::before{
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    background-image: linear-gradient(
      rgba(#2D3949, 0),
      #2D3949
      );
    width: 100%; height: 75%;
    top: 25%; left: 0;
}
video {
  width: 100%;
  margin-top: -15%;
  opacity: 1;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        line-height: 25px;
        font-size: 20px;
    }
    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        line-height: 25px;
      /* font-size: 18px; */
    }
    li {
        font-size: 20px;
    }
    h1{
        margin: 0 !important;
    }
    .divImg {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .pure-g [class*="pure-u"] {
        font-family:  'Lato', sans-serif !important;
    }
    .divCaption {
        display: block;
        clear: both;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        color: #574343;
    }
    .divBlogStory h1, .divBlogStory h2 {
        --tw-text-opacity: 1;
        color: rgba(185, 28, 28, var(--tw-text-opacity));
        letter-spacing: 1px;
        margin: 0 auto;
    }

    .masonry-item-1-3, .masonry-item-2-3 {
        margin: 5px;
        position: relative;
    }
    .threeCol img {
        max-width: 300px;
    }
    
  .masonry-img {
      padding:1px;
  }

  .masonry-item-text {
  
      position: relative;
      display: flex;  
      align-items: center;  
      justify-content: center;
      bottom: 30px;
      margin: 1px;
      background: #000;
      line-height: 20px;
      min-height: 40px;
      color: #FFF;
  
  }
  
   
}

@layer components  {
    .hidden-tw {
      @apply hidden
    }
    .mceTmpl {
        @apply my-8
    }
    .blue-btn {
        @apply bg-blue-dark hover:bg-green-light 
    }
}

/* Styling of instructional text */
.InsertTemplate {
    display: none;
}